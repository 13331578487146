// App.js
import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Header from "./components/Header/Header";
import Home from "./components/Home/Home";
import Footer from "./components/Footer2/Footer2.jsx";
import Catalogo from "./components/Catalogo/Catalogo";
import Pedido from "./components/Pedido/Pedido";
import Beneficios from "./components/Beneficios/BeneficiosAlimentosCongelados.js";
import Contacto from "./components/Contacto/Contact";
import ScrollToTopButton from "./components/ScrollToTopButton/ScrollToTopButton.js";
import Preloader from "./components/Preloader/Preloader";
import CookieBanner from "./components/CookieBanner/CookieBanner.js";
import SobreNosotros from "./components/SobreNosotros/SobreNosotros.js";
import Historia from "./components/HIstoria/HistoriaEmpresa.js";

// IMPORTS DE LOS DIFERENTES CATALOGOS
import Marisco from "./components/categorias/marisco";
import Pescado from "./components/categorias/pescado";
import Precocinado from "./components/categorias/precocinado";
import Patata from "./components/categorias/patata";
import Verdura from "./components/categorias/verdura";
import Carne from "./components/categorias/carne";
import Hielo from "./components/categorias/hielo";
import Helados from "./components/categorias/helados";
import Cocidos from "./components/categorias/cocido.js";

function App() {
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    setTimeout(() => setIsLoading(false), 1000); // Simula el tiempo de carga
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  if (isLoading) {
    return <Preloader />; // Muestra el Preloader mientras se carga
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <div className="App">
              <Header />
              <section id="home">
                <Home />
              </section>
              <section id="catalogo">
                <Catalogo />
              </section>
              <section id="pedido">
                <Pedido />
              </section>
              <Beneficios />
              <section id="historia">
                <Historia />
              </section>
              <SobreNosotros />
              <section id="contacto">
                <Contacto />
              </section>
              <CookieBanner />
              <Footer />
              <ScrollToTopButton isVisible={isVisible} onClick={scrollToTop} />
            </div>
          }
        />
        <Route path="/category/mariscos" element={<Marisco />} />
        <Route path="/category/pescados" element={<Pescado />} />
        <Route path="/category/precocinados" element={<Precocinado />} />
        <Route path="/category/patatas" element={<Patata />} />
        <Route path="/category/verduras" element={<Verdura />} />
        <Route path="/category/carnes" element={<Carne />} />
        <Route path="/category/hielo y tartas" element={<Hielo />} />
        <Route path="/category/helados" element={<Helados />} />
        <Route path="/category/mariscos recien cocidos" element={<Cocidos />} />
      </Routes>
    </Router>
  );
}

export default App;
