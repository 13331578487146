import React from "react";
import ProductCatalog from "../ProductCatalog/ProductCatalog";

const products = [
  {
    name: "Camarón Cocido",
    imgSrc: "/assets/fotos/marisco/camaroncocido.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Gamba Cocida G",
    imgSrc: "/assets/fotos/marisco/gambacocidag.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Gamba Cocida M",
    imgSrc: "/assets/fotos/marisco/gambacocidam.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Langostino Cocido 20-30",
    imgSrc: "/assets/fotos/marisco/langostinococido2030.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Langostino Cocido 30-40",
    imgSrc: "/assets/fotos/marisco/langostinococido3040.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Langostino Cocido 40-60",
    imgSrc: "/assets/fotos/marisco/langostinococido4060.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Nécora Cocida",
    imgSrc: "/assets/fotos/marisco/necoracocida.png",
    allergens: ["crustaceos"],
  },
  {
    name: "Pata Boca Cocida",
    imgSrc: "/assets/fotos/marisco/patabocacocida.png",
    allergens: ["crustaceos"],
  },

  {
    name: "Pata de pulpo cocida",
    imgSrc: "/assets/fotos/pescado/patadepulpococida.png",
    allergens: ["moluscos"],
  },
  {
    name: "Pulpo cocido",
    imgSrc: "/assets/fotos/pescado/pulpococido(s,m,g).png",
    allergens: ["moluscos"],
  },
];

const Cocido = () => {
  return <ProductCatalog title="MARISCOS COCIDOS" products={products} />;
};

export default Cocido;
