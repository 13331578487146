import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Zoom } from "react-reveal";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./SobreNosotros.css";

import Imagen1 from "../../imagenes/fripisa.jpg";
import Imagen2 from "../../imagenes/fripisa2.jpg";
import Imagen3 from "../../imagenes/fripisa3.jpg";


const NextArrow = ({ onClick }) => (
  <div className="arrow next" onClick={onClick}>
    <FaChevronRight />
  </div>
);

const PrevArrow = ({ onClick }) => (
  <div className="arrow prev" onClick={onClick}>
    <FaChevronLeft />
  </div>
);

const imagenes = [
  {
    url: Imagen1,
    descripcion: "Texto aquí",
  },
  {
    url: Imagen2,
    descripcion: "Texto aquí",
  },
  {
    url: Imagen3,
    descripcion: "Texto aquí",
  },
];

const CarruselImagenes = () => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 5000,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div id="carrusel" style={{ scrollMarginTop: "100px" }}>
      <div className="sobre-nosotros">
        <h2>
          SOBRE <span className="nosotros-color"> NOSOTROS</span>
        </h2>
        <p>
          En Fripisa, nos especializamos en la venta de productos congelados de
          alta calidad desde 1986. Nos dedicamos a ofrecer opciones frescas y
          convenientes, asegurando la satisfacción de nuestros clientes con cada
          compra.
        </p>
      </div>
      <Zoom>
        <Slider {...settings}>
          {imagenes.map((imagen, index) => (
            <div key={index}>
              <div className="imagen-container">
                <img
                  className="imagen-carrusel"
                  src={imagen.url}
                  alt={`Imagen ${index + 1}`}
                />
                <div className="descripcion-container">
                  <p className="descripcion-carrusel">{imagen.descripcion}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Zoom>
    </div>
  );
};

export default CarruselImagenes;
