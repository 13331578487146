import React, { useState } from "react";
import "../bootstrap/css/bootstrap.rtl.min.css";
import "./bootstap-especifico/bootstrap-icons.css";
import "./Footer2.css";

const Footer2 = () => {
  const [showCookiesModal, setShowCookiesModal] = useState(false);
  const [showDataProtectionModal, setShowDataProtectionModal] = useState(false);

  const handleCookiesModalOpen = (event) => {
    event.preventDefault();
    setShowCookiesModal(true);
  };

  const handleCookiesModalClose = () => setShowCookiesModal(false);

  const handleDataProtectionModalOpen = (event) => {
    event.preventDefault();
    setShowDataProtectionModal(true);
  };

  const handleDataProtectionModalClose = () =>
    setShowDataProtectionModal(false);

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-column footer-column-logo">
          <img
            src="/assets/fotos/logo2.png"
            alt="Logo"
            className="footer-logo"
          />
          <div className="social-media-icons">
            <a
              href="https://www.facebook.com/fripisasl"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/fotos/facebook.png"
                alt="Facebook"
                className="social-icon"
              />
            </a>
            <a
              href="https://www.instagram.com/fripisasl?utm_source=ig_web_button_share_sheet&igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/assets/fotos/instagram.png"
                alt="Instagram"
                className="social-icon"
              />
            </a>
          </div>
        </div>

        <div className="footer-column">
          <h4 className="footer-section-title">Servicios</h4>
          <ul>
            <li>
              <a href="#home" className="footer-menu-link">
                Inicio
              </a>
            </li>
            <li>
              <a href="#catalogo" className="footer-menu-link">
                Catálogo
              </a>
            </li>
            <li>
              <a href="#pedido" className="footer-menu-link">
                Empresa
              </a>
            </li>
            <li>
              <a href="#" className="footer-menu-link">
                Sobre Nosotros
              </a>
            </li>
            <li>
              <a href="#contacto" className="footer-menu-link">
                Contacto
              </a>
            </li>
          </ul>
        </div>
        <div className="footer-column">
          <h4 className="footer-section-title">Contacto</h4>
          <p>
            <i className="bi-geo-alt me-2"></i> N-420, 66, 13500 Puertollano,
            Ciudad Real
          </p>
          <p>
            <i className="bi-telephone me-2"></i>
            <a href="tel:926410517" className="footer-menu-link">
              {" "}
              926 41 05 17
            </a>
          </p>
          <p>
            <i className="bi-envelope me-2"></i>
            <a href="mailto:info@company.com" className="footer-menu-link">
              {" "}
              fripisasl@telefonica.net
            </a>
          </p>
        </div>
        <div className="footer-column">
          <h4 className="footer-section-title">
            Política de Privacidad y Cookies
          </h4>
          <p
            className="footer-menu-link"
            onClick={handleDataProtectionModalOpen}
          >
            <a href="#">Protección de Datos</a>
          </p>
          <p className="footer-menu-link" onClick={handleCookiesModalOpen}>
            <a href="#">Política de Cookies</a>
          </p>
        </div>
      </div>

      {showCookiesModal && (
        <div className="modal show" onClick={handleCookiesModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleCookiesModalClose}>
              &times;
            </span>
            <h2>POLÍTICA DE COOKIES</h2>
            <div className="modal-body">
              <p>
                El objetivo de la presente Política es trasladar al usuario de
                manera transparente la existencia y el uso de cookies que
                realiza FRIPISA, S.L. (en adelante, “FRIPISA”) en su página web,
                informando sobre su definición, la tipología de cookies
                utilizadas, identificando quién las utiliza, así como las
                opciones para gestionar su consentimiento o su rechazo al empleo
                de las mismas, el periodo de conservación de los datos y aportar
                información relevante en materia de protección de datos.
              </p>
              <h3>Información general</h3>
              <h4>1. ¿Qué son las cookies?</h4>
              <p>
                Las cookies son ficheros que se descargan en el dispositivo del
                usuario (como un teléfono móvil u ordenador) a través de los
                sitios web que un usuario visita. Las cookies se descargan
                mientras se navega por Internet y su finalidad principal es
                recabar datos de navegación del usuario para mejorar los
                servicios y el funcionamiento de la página web, elaborar
                información estadística y mostrarle contenido personalizado.
              </p>
              <p>
                Le informamos que en el transcurso de su navegación por este
                dominio de FRIPISA se utilizan cookies propias técnicas, que
                permiten el acceso a la navegación y a los servicios que se
                ofrecen en el sitio web; cookies propias de personalización que,
                si lo autoriza, recordarán sus preferencias mientras navega por
                el sitio web; y cookies propias gestionadas por terceros que, si
                lo autoriza, analizarán su navegación con fines estadísticos.
                Los usos concretos que hacemos de estas tecnologías se describen
                a continuación.
              </p>
              <h4>2. ¿Para qué utilizamos las cookies en este sitio web?</h4>
              <p>
                En general, utilizamos las cookies para conocer la actividad de
                los visitantes a nuestra página web, en concreto para obtener
                información relacionada con la fecha y hora de visita, así como
                preferencias en sus hábitos de navegación, analizar sus hábitos
                de navegación para introducir cambios en el sitio web que
                mejoren su rendimiento o el acceso a productos en los que se
                haya mostrado mayor interés. También se utilizan para
                proporcionar información sobre la navegación de los usuarios a
                los propietarios de otros sitios web y sus colaboradores, con el
                fin de analizar el uso que realizan nuestra página web.
              </p>
              <p>
                Al navegar por nuestra página web se utilizan cookies que pueden
                clasificarse de la siguiente manera:
              </p>
              <h4>
                Según el plazo de conservación en el dispositivo del usuario:
              </h4>
              <ul>
                <li>
                  <strong>Las cookies de sesión</strong> son aquellas cuya
                  persistencia en el dispositivo empleado por el usuario se
                  limita al tiempo que el mismo esté navegando por el presente
                  sitio web. Están diseñadas para recabar y almacenar datos
                  mientras el usuario accede a una página web. Se suelen emplear
                  para almacenar información que solo interesa conservar para la
                  prestación del servicio solicitado por el usuario en una sola
                  ocasión.
                </li>
                <li>
                  <strong>Las cookies persistentes</strong> son aquellas que se
                  mantienen en el dispositivo del usuario más allá de la sesión
                  de navegación. En particular, permiten que los datos sigan
                  almacenados en el terminal y a los que se puede acceder y
                  tratar durante un periodo definido por el responsable de la
                  cookie, y que puede ir de unos minutos a varios años.
                </li>
              </ul>
              <h4>Según la Entidad que las gestione:</h4>
              <ul>
                <li>
                  <strong>Las cookies propias</strong> son aquellas creadas o
                  gestionadas por el responsable de la página web, en este caso,
                  FRIPISA, y que se envían al equipo terminal del usuario desde
                  un equipo o dominio gestionado por el propio editor y desde el
                  que se presta el servicio solicitado por los usuarios.
                  Utilizamos cookies propias persistentes con fines técnicos
                  para controlar la visualización del banner informativo sobre
                  cookies.
                </li>
                <li>
                  <strong>Las cookies de terceros</strong> son aquéllas que se
                  envían al equipo terminal del usuario desde un equipo o
                  dominio que no es gestionado por el editor, sino por otra
                  entidad que trata los datos obtenidos través de las cookies.
                </li>
              </ul>
              <h4>
                Según la finalidad para la que se traten los datos obtenidos a
                través de las cookies:
              </h4>
              <ul>
                <li>
                  <strong>Cookies técnicas (necesarias):</strong> Son aquellas
                  que permiten al usuario la navegación a través de una página
                  web, plataforma o aplicación y la utilización de las
                  diferentes opciones o servicios que en ella existan. Por
                  ejemplo, las utilizamos, principalmente, para el mantenimiento
                  de la sesión, el cálculo de tiempo de respuesta, realizar la
                  solicitud de inscripción o participación en un evento, usar
                  elementos de seguridad durante tu navegación y habilitar
                  contenidos dinámicos.
                </li>
                <li>
                  <strong>Cookies de personalización (preferencias):</strong>{" "}
                  Son aquéllas que permiten al usuario acceder al servicio con
                  algunas características de carácter general predefinidas, en
                  función de una serie de criterios en el terminal del usuario
                  (por ejemplo, el idioma, el tipo de navegador a través del
                  cual accede al servicio, la configuración regional desde donde
                  accede al servicio, etc.).
                </li>
                <li>
                  <strong>
                    Cookies de análisis o de medición (estadísticas):
                  </strong>
                  Son aquellas que permiten el seguimiento y análisis del
                  comportamiento de los usuarios de los sitios web a los que
                  están vinculadas. La información recogida mediante este tipo
                  de cookies se utiliza en la medición de la actividad de los
                  sitios web, aplicación o plataforma y para la elaboración de
                  perfiles de navegación de los usuarios de dichos sitios, con
                  el fin de introducir mejoras en función del análisis de los
                  datos de uso que hacen los usuarios del servicio.
                </li>
                <li>
                  <strong>Cookies publicitarias (marketing):</strong> Son
                  aquéllas que permiten la gestión, de la forma más eficaz
                  posible, de los espacios publicitarios que, en su caso, se
                  hayan incluido en una página web, aplicación o plataforma
                  desde la que se presta el servicio solicitado, en base a
                  criterios como el contenido editado o la frecuencia en la que
                  se muestran los anuncios. Incluye las Cookies de publicidad
                  comportamental que almacenan información del comportamiento de
                  los usuarios obtenida a través de la observación continuada de
                  sus hábitos de navegación, lo que permite desarrollar un
                  perfil específico para mostrar publicidad en función del
                  mismo.
                </li>
              </ul>
              <h4>
                3. ¿Cómo puedo otorgar, denegar o revocar mi consentimiento?
              </h4>
              <p>
                El empleo de cookies imprescindibles y estrictamente necesarias
                para el correcto funcionamiento del sitio web no se encuentra
                sujeto a aceptación por parte del usuario. Por este motivo, no
                podrá modificar su valor en el Panel de Configuración de
                Cookies.
              </p>
              <p>
                La instalación de cualquier otra tipología de cookies empleadas
                en este sitio web se encuentra sujeto a aceptación expresa y
                previa por parte del usuario mediante la aceptación o denegación
                de las mismas a través del panel de configuración mostrado al
                abrir la página.
              </p>
              <p>
                Le informamos que, en caso de no seleccionar ninguna opción del
                panel de configuración, equivaldría a un rechazo, de modo que no
                serán instaladas ni empleadas en su dispositivo cookies que no
                sean indispensables.
              </p>
              <p>
                Finalmente, le informamos que nosotros no controlamos la
                persistencia de las cookies de terceros, por lo que, si acepta
                su uso, deberá eliminarlas desde las opciones del navegador o
                desde el sistema ofrecido por el propio tercero.
              </p>
              <h3>
                Información sobre Protección de Datos relacionada con Cookies
              </h3>
              <p>
                El empleo de cookies puede implicar la recopilación de datos
                personales de los usuarios de este sitio web. Por este motivo,
                en cumplimiento de la legislación vigente, ponemos en su
                conocimiento la siguiente información:
              </p>
              <h4>1. Responsable de tratamiento</h4>
              <p>
                El Responsable del tratamiento de sus datos de navegación,
                obtenidos a través de las cookies en nuestra página web es
                FRIPISA, S.L. (en adelante, “FRIPISA”)
              </p>
              <p>
                Datos de contacto del responsable: Calle Asdrubal, 59, 13.500 –
                Puertollano (Ciudad Real)
              </p>
              <p>
                Datos de contacto del Delegado de Protección de Datos:
                fripisasl@telefonica.net
              </p>
              <h4>2. Datos tratados</h4>
              <p>
                La tipología de datos que se tratan a través de las cookies son
                datos identificativos, en concreto, los datos obtenidos de sus
                navegaciones por el sitio web y la navegación que realice en la
                misma: historial de navegación (páginas visitadas y clics en
                contenidos).
              </p>
              <h4>3. Finalidades del tratamiento y Legitimación</h4>
              <p>
                Las finalidades del tratamiento están indicadas en el apartado 2
                "¿Para qué utilizamos las cookies en este sitio web?". Aquellas
                cookies que sean necesarias para el funcionamiento de nuestra
                página web se instalarán en su dispositivo. Para todos los demás
                tipos de cookies necesitamos su consentimiento.
              </p>
              <p>
                En particular, si consiente las cookies de publicidad y de
                publicidad comportamental (marketing), podremos utilizar los
                datos que obtengamos de su navegación para almacenarlos y
                elaborar un perfil sobre usted, de manera que podamos ofrecerle
                publicidad que pueda ser de su interés.
              </p>
              <h4>4. Destinatarios de los datos</h4>
              <p>
                Serán destinatarios de sus datos los terceros proveedores de
                Cookies que se encuentren instaladas en el presente sitio web.
              </p>
              <h4>5. Transferencias a terceros países</h4>
              <p>
                El tratamiento de los datos se realiza, con carácter general, en
                el Espacio Económico Europeo o en países que han sido declarados
                con un nivel adecuado de protección.
              </p>
              <h4>6. Periodo de conservación de los datos</h4>
              <p>
                Las Cookies se encontrarán instaladas por el tiempo necesario
                para cumplir con las finalidades que persiguen.
              </p>
              <h4>7. Derechos de los usuarios</h4>
              <p>
                Como titular de los datos usted tiene derecho a acceder,
                actualizar, rectificar y suprimir los datos, así como otros
                derechos, o solicitar más información sobre el tratamiento que
                realizamos de las cookies dirigiéndose al correo electrónico
                fripisasl@telefonica.net
              </p>
              <p>
                Tiene derecho a presentar cualquier reclamación ante la
                autoridad de control competente, considerando que la misma es,
                en España, la Agencia Española de Protección de Datos. Para
                poder ejercitar sus derechos en materia de protección de datos
                puede contactarnos a través de correo electrónico
                fripisasl@telefonica.net.
              </p>
              <h4>Actualizaciones y cambios en la Política de Cookies</h4>
              <p>
                FRIPISA realizará una revisión de la Política de cookies
                anualmente, salvo que existan exigencias normativas o de otra
                índole que hagan necesario adaptar la Política con una
                periodicidad inferior. Por tanto, se aconseja a los usuarios que
                consulten periódicamente su contenido, con el objetivo de estar
                informado adecuadamente sobre cómo y para qué utilizamos las
                cookies.
              </p>
              <p>Fecha de la última actualización: Agosto de 2024.</p>
            </div>
          </div>
        </div>
      )}

      {showDataProtectionModal && (
        <div className="modal show" onClick={handleDataProtectionModalClose}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <span className="close" onClick={handleDataProtectionModalClose}>
              &times;
            </span>
            <h2>Política de Protección de Datos</h2>
            <div className="modal-body">
              <h3>
                1. Responsable del Tratamiento ¿Quién es el responsable del
                tratamiento de tus datos?
              </h3>
              <p>
                1.1. FRIPISA, S.L. (en adelante, “FRIPISA”), con CIF B13035449 y
                domicilio en Calle Asdrubal, 59, 13.500 – Puertollano (Ciudad
                Real). Contáctanos por el correo electrónico de atención al
                cliente fripisasl@telefonica.net.
              </p>
              <p>
                1.2. El Delegado de Protección de Datos (en adelante DPD) es la
                persona encargada de salvaguardar su privacidad en nuestra
                Entidad. Si precisa contactar con él, puede hacerlo en
                fripisasl@telefonica.net o mediante carta remitida a la
                dirección de la Entidad, incluyendo la referencia “Delegado de
                Protección de Datos”.
              </p>
              <h3>2. Alcance ¿A quién aplica esta Información?</h3>
              <p>
                2.1. La presente información en materia de Protección de Datos
                aplica exclusivamente a los tratamientos que se realizan de los
                datos recabados a través de los formularios habilitados en las
                páginas web del responsable del tratamiento.
              </p>
              <h3>
                3. Procedencia y categoría de datos ¿Cuál es el origen y que
                categorías de datos tratamos?
              </h3>
              <p>
                3.1. Los datos personales que se gestionan a través de la
                presente página web serán exclusivamente los que nos facilite a
                través de los formularios habilitados. No se obtienen datos
                personales procedentes de otras fuentes.
              </p>
              <p>
                3.2. Las categorías de datos tratados, en los tratamientos de
                datos que se exponen en el apartado 4 de la presente
                Información, son datos identificativos y de contacto: nombre,
                apellidos y email, así como cualquier otro dato de carácter
                personal que usted incluya en el cuerpo del mensaje.
              </p>
              <h3>
                4. Finalidad ¿Con qué finalidad tratamos sus datos personales?
              </h3>
              <p>
                4.1. El Responsable tratará los datos identificativos y de
                contacto para ponernos en contacto usted y atender las consultas
                y solicitudes de información que nos remita a través de
                formularios habilitados en nuestra página web.
              </p>
              <p>
                4.2. En el caso de que lo autorice expresamente, y siempre que
                no sea cliente de FRIPISA, emplearemos tus datos de contacto
                para remitirle comunicaciones comerciales de productos
                comercializados por FRIPISA. Adicionalmente, en el caso de que
                nos facilite su número de teléfono en el formulario web,
                podremos tratar su dato de teléfono para contactarle y
                proporcionarle información comercial de nuestros productos.
                Asimismo, si nos facilita datos identificativos y de contacto
                durante la llamada serán utilizados con la finalidad de llevar
                un seguimiento del producto ofrecido durante la misma.
              </p>
              <p>
                4.3. No se prevé la realización de transferencias
                internacionales de datos, ni la elaboración de perfiles o
                decisiones automatizadas para los tratamientos identificados.
              </p>
              <h3>
                5. Legitimación ¿Cuál es la legitimación para el tratamiento de
                tus datos?
              </h3>
              <p>
                5.1. La legitimación para el tratamiento de tus datos personales
                para la finalidad enumerada en el apartado 4.1 es el
                consentimiento expreso y libre prestado por usted al marcar la
                casilla “He leído y acepto la Política de Protección de Datos”,
                así como la ejecución de medidas precontractuales a solicitud
                del interesado, en caso de que en su consulta se interese por
                los productos ofrecidos por FRIPISA.
              </p>
              <p>
                5.2. La finalidad para el tratamiento de tus datos personales
                para la finalidad enumerada en el apartado 4.2 es el
                consentimiento expreso y libre prestado por usted a través de la
                casilla “Deseo recibir comunicaciones comerciales de productos
                ofrecidos por FRIPISA”.
              </p>
              <h3>6. Conservación ¿Cuánto tiempo conservaremos tus datos?</h3>
              <p>
                6.1. Los datos recabados a través de los formularios habilitados
                en nuestras páginas web se conservarán durante el plazo de un
                año. Trascurrido ese plazo los datos se eliminarán.
              </p>
              <h3>
                7. Destinatarios ¿A qué destinatarios se comunicarán tus datos?
              </h3>
              <p>
                7.1. Los datos se tratarán por el responsable del tratamiento,
                así como por los agentes y proveedores con los que FRIPISA
                contrate la prestación de algún servicio, en caso de que resulte
                necesario. Ello se hará siempre con contratos y garantías
                sujetos a los modelos aprobados por las autoridades en materia
                de protección de datos.
              </p>
              <h3>8. Derechos ¿Cuáles son tus derechos?</h3>
              <p>
                8.1. Derecho de acceso: Derecho a conocer qué tipo de datos
                estamos tratando y las características de los tratamientos que
                estamos llevando a cabo, de acuerdo con lo establecido en el
                artículo 15 del Reglamento General de Protección de Datos (RGPD,
                en adelante).
              </p>
              <p>
                8.2. Derecho de rectificación: Derecho a solicitar la
                modificación de tus datos personales por ser éstos inexactos, no
                veraces o desactualizados.
              </p>
              <p>
                8.3. Derecho de oposición: Derecho a solicitar que no se traten
                tus datos personales para determinadas finalidades. Así, cuando
                el tratamiento esté basado en tu consentimiento, tienes derecho
                a retirar tal consentimiento en cualquier momento. Igualmente
                podrás oponerse al tratamiento de tus datos. Todo ello de
                acuerdo con el artículo 21 del RGPD.
              </p>
              <p>
                8.4. Derecho de cancelación o supresión: Derecho a solicitar la
                supresión de tus datos personales cuando el tratamiento ya no
                resulte necesario.
              </p>
              <p>
                8.5. Derecho limitación al tratamiento: Derecho a solicitar la
                limitación del tratamiento de tus datos, en cuyo caso únicamente
                se conservarán para el ejercicio o la defensa de reclamaciones,
                atención a requerimientos judiciales o a exigencias legales.
              </p>
              <p>
                8.6. Portabilidad. Derecho a solicitar la portabilidad de los
                datos que nos hayas facilitado, para recibirlos en un formato
                estructurado, de uso común y lectura mecánica, y a que se
                transmitan a otro responsable, de acuerdo con lo establecido en
                el artículo 20 del RGPD.
              </p>
              <p>
                8.7. Revocación del consentimiento. En cualquier momento puedes
                revocar el consentimiento que hayas prestado, sin ningún tipo de
                detrimento o perjuicio.
              </p>
              <p>
                8.8. Presentación de una reclamación. En cualquier momento
                puedes presentar reclamación ante la autoridad de control
                competente, considerando que la misma es, en España, la Agencia
                Española de Protección de Datos, sin perjuicio de las
                competencias que en su caso ostenten otras entidades autonómicas
                o supranacionales, de acuerdo con el Reglamento de Protección de
                Datos y la normativa nacional.
              </p>
              <p>
                También puedes acudir a nuestro Delegado de Protección de Datos
                a través de correo electrónico fripisasl@telefonica.net.
              </p>
              <p>
                Para el ejercicio de cualquiera de los derechos enunciados,
                puedes dirigir tu solicitud mediante escrito dirigido a FRIPISA
                en la dirección de correo electrónico fripisasl@telefonica.net,
                adjuntando copia de su DNI u otro documento oficial
                identificativo.
              </p>
            </div>
          </div>
        </div>
      )}

      <div className="footer-bottom">
        <p>© 2024 Fripisa, Todos los Derechos Reservados.</p>
        <a
          href="https://www.noos-solutions.es/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            src="/assets/fotos/powered_by_blanco.png"
            alt="Logo"
            className="footer-bottom-image"
          />
        </a>
      </div>
    </footer>
  );
};

export default Footer2;
