import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import('../ProductCatalog/ProductCatalog'));

const products = [
  {
    "name": "Albondigas de Pollo",
    "imgSrc": "/assets/fotos/precocinados/albondigasdepollo.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Albondigas de Ternera",
    "imgSrc": "/assets/fotos/precocinados/albondigasdeternera.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Aros de Cebolla",
    "imgSrc": "/assets/fotos/precocinados/aroscebolla.PNG",
    "allergens": ["gluten", "lacteos"]
  },
  {
    "name": "Bacalao Rebozado",
    "imgSrc": "/assets/fotos/precocinados/bacalaorebozado.PNG",
    "allergens": ["gluten", "pescado"]
  },
  {
    "name": "Berenjena Rebozada",
    "imgSrc": "/assets/fotos/precocinados/berenjenarebozada.PNG",
    "allergens": ["gluten", "huevos"]
  },
  {
    "name": "Bocaditos de Queso",
    "imgSrc": "/assets/fotos/precocinados/bocaditosdequeso.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Bolita Queso MC",
    "imgSrc": "/assets/fotos/precocinados/bolitaquesomc.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Buñuelos de Bacalao",
    "imgSrc": "/assets/fotos/precocinados/buñuelosbacalao.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Calabazin Rebozado",
    "imgSrc": "/assets/fotos/precocinados/calabazinrebozado.PNG",
    "allergens": ["gluten", "huevos"]
  },
  {
    "name": "Calamar a la Romana",
    "imgSrc": "/assets/fotos/precocinados/calamaralaromana.PNG",
    "allergens": ["gluten", "huevos", "moluscos"]
  },
  {
    "name": "Canelones de Ternera",
    "imgSrc": "/assets/fotos/precocinados/canelonesternera.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Churros Lazo",
    "imgSrc": "/assets/fotos/precocinados/churroslazo.PNG",
    "allergens": ["gluten"]
  },
  {
    "name": "Croquetas de Bacalao",
    "imgSrc": "/assets/fotos/precocinados/croquetasdebacalao.PNG",
    "allergens": ["gluten", "huevos", "lacteos", "pescado"]
  },
  {
    "name": "Croquetas de Iberico",
    "imgSrc": "/assets/fotos/precocinados/croquetasdeiberico.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Croquetas de Jamon",
    "imgSrc": "/assets/fotos/precocinados/croquetasjamon.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Croquetas de Pollo",
    "imgSrc": "/assets/fotos/precocinados/croquetaspollo.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Croqueta Artesana Andaluza",
    "imgSrc": "/assets/fotos/precocinados/croquetaartesanaandaluza.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Empanadilla de Atun",
    "imgSrc": "/assets/fotos/precocinados/empanadillaatun.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Fingers de Pollo",
    "imgSrc": "/assets/fotos/precocinados/fingerpollo.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Fritura de Pescado",
    "imgSrc": "/assets/fotos/precocinados/frituradepescado.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Gamba Rebozada",
    "imgSrc": "/assets/fotos/precocinados/gambarebozada.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Hamburguesa Crispy",
    "imgSrc": "/assets/fotos/precocinados/hamburguesacrispy.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Hamburguesa de Ternera",
    "imgSrc": "/assets/fotos/precocinados/hamburguesaternera.PNG",
    "allergens": ["gluten"]
  },
  {
    "name": "Hamburguesa de Buey",
    "imgSrc": "/assets/fotos/precocinados/hamburguesabuey.PNG",
    "allergens": ["gluten"]
  },
  {
    "name": "Hamburguesa Mixta Pollo Cerdo",
    "imgSrc": "/assets/fotos/precocinados/hamburguesamixtapollocerdo.PNG",
    "allergens": ["gluten"]
  },
  {
    "name": "Jalapeño Queso MC",
    "imgSrc": "/assets/fotos/precocinados/jalapeñoquesomc.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Langostino Crujiente",
    "imgSrc": "/assets/fotos/precocinados/langostinocrujiente.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Langostino Saquito",
    "imgSrc": "/assets/fotos/precocinados/langostinossaquito.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Langostino Ravioli",
    "imgSrc": "/assets/fotos/precocinados/langostinoravioli.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Langostino Torpedo",
    "imgSrc": "/assets/fotos/precocinados/langostinotorpedo.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Mejillon Tigre",
    "imgSrc": "/assets/fotos/precocinados/mejillontrigre.PNG",
    "allergens": ["gluten", "huevos", "moluscos"]
  },
  {
    "name": "Mini Croquetas",
    "imgSrc": "/assets/fotos/precocinados/minicroquetas.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Mini S. Jacobo",
    "imgSrc": "/assets/fotos/precocinados/minisanjacobo.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Mini Fla Menquin",
    "imgSrc": "/assets/fotos/precocinados/miniflamenquin.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Mini Hamburguesa",
    "imgSrc": "/assets/fotos/precocinados/minihamburguesa.PNG",
    "allergens": ["gluten"]
  },
  {
    "name": "Mini Empanadilla",
    "imgSrc": "/assets/fotos/precocinados/miniempanadilla.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Nachos Queso",
    "imgSrc": "/assets/fotos/precocinados/nachosqueso.PNG",
    "allergens": ["gluten", "lacteos"]
  },
  {
    "name": "Nuggets de Pollo",
    "imgSrc": "/assets/fotos/precocinados/nuggetspollo.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Palitos de Merluza",
    "imgSrc": "/assets/fotos/precocinados/palitosdemerluza.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Palitos de Mozzarella",
    "imgSrc": "/assets/fotos/precocinados/palitosmozzarella.png",
    "allergens": ["gluten", "lacteos"]
  },
  {
    "name": "Paninis Variados",
    "imgSrc": "/assets/fotos/precocinados/paninisvariados.PNG",
    "allergens": ["gluten", "lacteos"]
  },
  {
    "name": "Pimientos Rellenos",
    "imgSrc": "/assets/fotos/precocinados/pimientosrellenos.PNG",
    "allergens": ["gluten", "lacteos"]
  },
  {
    "name": "Rabas de Calamar",
    "imgSrc": "/assets/fotos/precocinados/rabascalamar.PNG",
    "allergens": ["gluten", "huevos", "moluscos"]
  },
  {
    "name": "Rodajas de Merluza",
    "imgSrc": "/assets/fotos/precocinados/rodajamerluzarebozada.PNG",
    "allergens": ["gluten", "huevos", "pescado"]
  },
  {
    "name": "Rollitos Primavera",
    "imgSrc": "/assets/fotos/precocinados/rollitosprimavera.PNG",
    "allergens": ["gluten", "soja"]
  },
  {
    "name": "Tiras Pollo Crujiente",
    "imgSrc": "/assets/fotos/precocinados/tiraspollocrujiente.PNG",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Tortilla de Patata",
    "imgSrc": "/assets/fotos/precocinados/tortillapatata.png",
    "allergens": ["huevos"]
  },
  {
    "name": "Tortillita de Camaron",
    "imgSrc": "/assets/fotos/precocinados/tortillitadecamaron.PNG",
    "allergens": ["gluten", "huevos", "crustaceos"]
  },
  {
    "name": "Verduras en Tempura",
    "imgSrc": "/assets/fotos/precocinados/verdurasentempura.PNG",
    "allergens": ["gluten", "huevos"]
  }
];

const Precocinado = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="PRECOCINADOS" products={products} />
    </Suspense>
  );
};

export default Precocinado;
