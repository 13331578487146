import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import "./Header.css";

const Header = ({ products = [] }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [query, setQuery] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [scrollDirection, setScrollDirection] = useState("up");
  const navigate = useNavigate();

  let lastScrollTop = 0;

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      if (scrollTop > lastScrollTop) {
        setScrollDirection("down");
      } else {
        setScrollDirection("up");
      }
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleSearch = () => {
    setSearchOpen(!searchOpen);
  };

  const handleInputChange = (event) => {
    const newQuery = event.target.value;
    setQuery(newQuery);
    filterProducts(newQuery, selectedCategory);
  };

  const filterProducts = (query, category) => {
    if (products && Array.isArray(products)) {
      const lowerCaseQuery = query.toLowerCase();
      const newFilteredProducts = products.filter((product) => {
        return (
          product.name.toLowerCase().includes(lowerCaseQuery) &&
          (!category || product.category === category)
        );
      });
      setFilteredProducts(newFilteredProducts);
    }
  };

  return (
    <>
      <header
        className={`header ${scrollDirection === "down" ? "hidden" : ""}`}
      >
        <div className="logo">
          <img src="/assets/fotos/logo3.png" alt="Logo" />
        </div>

        <nav className={`navigation ${menuOpen ? "open" : ""}`}>
          <ul>
            <li>
              <ScrollLink
                to="home"
                className="header-link"
                smooth={true}
                duration={100}
                offset={-70}
                onClick={toggleMenu}
              >
                Inicio
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="catalogo"
                className="header-link"
                smooth={true}
                duration={100}
                offset={-70}
                onClick={toggleMenu}
              >
                Catálogo
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="pedido"
                className="header-link"
                smooth={true}
                duration={100}
                offset={-70}
                onClick={toggleMenu}
              >
                Empresa
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="historia"
                className="header-link"
                smooth={true}
                duration={100}
                offset={-70}
                onClick={toggleMenu}
              >
                Historia
              </ScrollLink>
            </li>
            <li>
              <ScrollLink
                to="contacto"
                className="header-link"
                smooth={true}
                duration={100}
                offset={-70}
                onClick={toggleMenu}
              >
                Contacto
              </ScrollLink>
            </li>
            
          </ul>
        </nav>
        <button
          className={`custom-menu-button ${menuOpen ? "menu-open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="menu-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </header>
    </>
  );
};

export default Header;
