import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import('../ProductCatalog/ProductCatalog'));

const products = [
  {
    "name": "Bogavante Crudo",
    "imgSrc": "/assets/fotos/marisco/bogavantecrudo.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Bogavante Crudo Azul",
    "imgSrc": "/assets/fotos/marisco/bogavantecrudoazul.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Buey de Mar",
    "imgSrc": "/assets/fotos/marisco/bueydemar.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Camarón Cocido",
    "imgSrc": "/assets/fotos/marisco/camaroncocido.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Carabinero 5",
    "imgSrc": "/assets/fotos/marisco/carabinero5.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Carne de Cangrejo",
    "imgSrc": "/assets/fotos/marisco/carnecangrejo.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Carne de Vieira",
    "imgSrc": "/assets/fotos/marisco/carnevieira.png",
    "allergens": ["moluscos"]
  },
  {
    "name": "Cigala Número 00",
    "imgSrc": "/assets/fotos/marisco/cigalanum00.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Cigala Número 1",
    "imgSrc": "/assets/fotos/marisco/cigalanum1.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Arrocera",
    "imgSrc": "/assets/fotos/marisco/gambaarrocera.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Blanca Cruda",
    "imgSrc": "/assets/fotos/marisco/gambablancacruda.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Cocida G",
    "imgSrc": "/assets/fotos/marisco/gambacocidag.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Cocida M",
    "imgSrc": "/assets/fotos/marisco/gambacocidam.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Pelada 10-30",
    "imgSrc": "/assets/fotos/marisco/gambapelada1030.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Pelada 30-50",
    "imgSrc": "/assets/fotos/marisco/gambapelada3050.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Pelada 50-70",
    "imgSrc": "/assets/fotos/marisco/gambapelada5070.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Pelada 70-100",
    "imgSrc": "/assets/fotos/marisco/gambapelada70100.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gamba Roja Cruda",
    "imgSrc": "/assets/fotos/marisco/gambarojacruda.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gambón",
    "imgSrc": "/assets/fotos/marisco/gambon.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Gulas",
    "imgSrc": "/assets/fotos/marisco/gulas.png",
    "allergens": ["pescado"]
  },
  {
    "name": "Langosta Cruda",
    "imgSrc": "/assets/fotos/marisco/langostacruda.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Langostino Cocido 20-30",
    "imgSrc": "/assets/fotos/marisco/langostinococido2030.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Langostino Cocido 30-40",
    "imgSrc": "/assets/fotos/marisco/langostinococido3040.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Langostino Cocido 40-60",
    "imgSrc": "/assets/fotos/marisco/langostinococido4060.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Langostino Crudo 18-24",
    "imgSrc": "/assets/fotos/marisco/langostinocrudo1824.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Langostino Crudo 32-48",
    "imgSrc": "/assets/fotos/marisco/langostinocrudo3248.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Navajas Grandes",
    "imgSrc": "/assets/fotos/marisco/navajasg.png",
    "allergens": ["moluscos"]
  },
  {
    "name": "Navajas Pequeñas",
    "imgSrc": "/assets/fotos/marisco/navajasp.png",
    "allergens": ["moluscos"]
  },
  {
    "name": "Nécora Cocida",
    "imgSrc": "/assets/fotos/marisco/necoracocida.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Pata Boca Cocida",
    "imgSrc": "/assets/fotos/marisco/patabocacocida.png",
    "allergens": ["crustaceos"]
  },
  {
    "name": "Salpicón de Marisco",
    "imgSrc": "/assets/fotos/marisco/salpicondemarisco.png",
    "allergens": ["crustaceos", "moluscos"]
  },
  {
    "name": "Vieira Rellena de Marisco",
    "imgSrc": "/assets/fotos/marisco/vieirarellenamarisco.png",
    "allergens": ["moluscos", "crustaceos"]
  },
  {
    "name": "Vieiras Media Concha",
    "imgSrc": "/assets/fotos/marisco/vieirasmediaconcha.png",
    "allergens": ["moluscos"]
  },
  {
    "name": "Zamburiñas",
    "imgSrc": "/assets/fotos/marisco/zamburiñas.png",
    "allergens": ["moluscos"]
  }
];

const Marisco = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="MARISCOS" products={products} />
    </Suspense>
  );
};

export default Marisco;
