import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import("../ProductCatalog/ProductCatalog"));

const products = [
  {
    name: "Bloque nata",
    imgSrc: "/assets/fotos/helados/bloquenata.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bloque nata café",
    imgSrc: "/assets/fotos/helados/bloquenatafresa.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bloques variados",
    imgSrc: "/assets/fotos/helados/bloquesvariados.png",
    allergens: ["lacteos"],
  },
  {
    name: "Bloque tres gustos",
    imgSrc: "/assets/fotos/helados/bloquetresgustos.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bloque vainilla",
    imgSrc: "/assets/fotos/helados/bloquevainilla.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bloque vainilla choco",
    imgSrc: "/assets/fotos/helados/bloquevainillachoco.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bombón crocanti",
    imgSrc: "/assets/fotos/helados/bomboncrocanti.PNG",
    allergens: ["lacteos", "frutos de cascara"],
  },
  {
    name: "Bombón filipinos",
    imgSrc: "/assets/fotos/helados/bombonfilipinos.PNG",
    allergens: ["gluten", "lacteos", "frutos de cascara"],
  },
  {
    name: "Bombón kojak",
    imgSrc: "/assets/fotos/helados/bombonkojak.png",
    allergens: ["lacteos"],
  },
  {
    name: "Bombón sin azúcar",
    imgSrc: "/assets/fotos/helados/bombonsinazucar.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bombón nata",
    imgSrc: "/assets/fotos/helados/bombonnata.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bombón vainilla",
    imgSrc: "/assets/fotos/helados/bombonvainilla.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Bombón variado",
    imgSrc: "/assets/fotos/helados/bombonvariado.png",
    allergens: ["lacteos"],
  },
  {
    name: "Cono cookie choco",
    imgSrc: "/assets/fotos/helados/conocookiechoco.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Cono nata choco",
    imgSrc: "/assets/fotos/helados/conosnatachoco.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Cono nata fresa",
    imgSrc: "/assets/fotos/helados/conosnatafresa.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Cono nata turrón",
    imgSrc: "/assets/fotos/helados/conosnataturron.PNG",
    allergens: ["gluten", "lacteos", "frutos de cascara"],
  },
  {
    name: "Cono sin azúcar",
    imgSrc: "/assets/fotos/helados/conossinazucar.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Cono vainilla choco",
    imgSrc: "/assets/fotos/helados/conosvainillachoco.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Cono vainilla",
    imgSrc: "/assets/fotos/helados/conosvainilla.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Conos variados",
    imgSrc: "/assets/fotos/helados/conosvariados.png",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Galleta helado",
    imgSrc: "/assets/fotos/helados/galletashelado.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Pioninos",
    imgSrc: "/assets/fotos/helados/pioninos.png",
    allergens: ["gluten", "lacteos", "huevos"],
  },
  {
    name: "Polo Megatorcida",
    imgSrc: "/assets/fotos/helados/polomegatorcidafiesta.png",
    allergens: ["ninguno"],
  },
  {
    name: "Polo horchata",
    imgSrc: "/assets/fotos/helados/poloshorchata.PNG",
    allergens: ["ninguno"],
  },
  {
    name: "Polo frutinos",
    imgSrc: "/assets/fotos/helados/polosfrutinos.PNG",
    allergens: ["ninguno"],
  },
  {
    name: "Profiteroles",
    imgSrc: "/assets/fotos/helados/profiteroles.png",
    allergens: ["gluten", "huevos", "lacteos"],
  },
  {
    name: "Sandwich nata",
    imgSrc: "/assets/fotos/helados/sandwichnata.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Sandwich nata choco",
    imgSrc: "/assets/fotos/helados/sandwichvainillachocolate.PNG",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Sandwich variado",
    imgSrc: "/assets/fotos/helados/sandwichvariado.png",
    allergens: ["gluten", "lacteos"],
  },
  {
    name: "Surtido bombón mini",
    imgSrc: "/assets/fotos/helados/surtidobombonmini.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Surtido familiar",
    imgSrc: "/assets/fotos/helados/surtidofamiliar.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Tarta whisky",
    imgSrc: "/assets/fotos/helados/tartawhisky.PNG",
    allergens: ["gluten", "huevos", "lacteos"],
  },
  {
    name: "Tarta crujiente",
    imgSrc: "/assets/fotos/helados/tartacrujiente.PNG",
    allergens: ["gluten", "huevos", "lacteos"],
  },
  {
    name: "Vasito verona nata caramelo",
    imgSrc: "/assets/fotos/helados/vasitoveronanatacaramelo.PNG",
    allergens: ["lacteos"],
  },
  {
    name: "Vasito verona vainilla choco",
    imgSrc: "/assets/fotos/helados/vasitoveronavainillachoco.PNG",
    allergens: ["lacteos"],
  },
];

const Helados = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="HELADOS" products={products} />
    </Suspense>
  );
};

export default Helados;
