import React from "react";
import "./Pedido.css";
import { Zoom } from "react-reveal";

const PasosVenta = () => {
  const handleCallClick = () => {
    window.location.href = "tel:+926 41 05 17"; // Reemplaza con el número de teléfono correcto
  };

  return (
    <section className="shipping-steps">
      <h2>FRIPISA, EN TODOS SITIOS</h2>
      <Zoom>
        <div className="step" onClick={handleCallClick} style={{ cursor: "pointer" }}>
          <img src="/assets/fotos/llamada.png" alt="Paso de Realizar Pedido" />
          <h3>LLÁMANOS</h3>
          <p>
            Llama para seleccionar tus productos congelados favoritos y realiza tu pedido
            fácilmente en línea.
          </p>
        </div>
        <div className="step">
          <img src="/assets/fotos/carrito-de-compras.png" alt="Paso de Envío" />
          <h3>VENTA DIRECTA</h3>
          <p>
            Recoge tu pedido en nuestras instalaciones gracias al servicio de venta directa.
          </p>
        </div>
        <div className="step">
          <img src="/assets/fotos/restaurante.png" alt="Paso de En Casa" />
          <h3>DIRECTO A TU RESTAURANTE</h3>
          <p>
            Entregamos tu pedido congelado directamente en tu restaurante o
            establecimiento de hostelería.
          </p>
        </div>
      </Zoom>
    </section>
  );
};

export default PasosVenta;
