import React from "react";
import { useNavigate } from "react-router-dom";
import "./Catalogo.css";

const categories = [
  { name: "MARISCOS", image: "/assets/fotos/categorias/marisco.jpg" },
  { name: "PESCADOS", image: "/assets/fotos/categorias/pescado.jpg" },
  { name: "PRECOCINADOS", image: "/assets/fotos/categorias/precocinados.jpg" },
  { name: "PATATAS", image: "/assets/fotos/categorias/patata.jpg" },
  { name: "VERDURAS", image: "/assets/fotos/categorias/verdura.jpg" },
  { name: "CARNES", image: "/assets/fotos/categorias/carne.jpg" },
  { name: "HIELO Y TARTAS", image: "/assets/fotos/categorias/hielotartas.jpg" },
  { name: "HELADOS", image: "/assets/fotos/categorias/helado.jpg" },
  { name: "MARISCOS RECIEN COCIDOS", image: "/assets/fotos/categorias/mariscococido.jpg", extraWide: true },
];

const CategoryButton = ({ category, image, extraWide }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/category/${category.toLowerCase()}`);
  };

  return (
    <div className={`category-button ${extraWide ? 'extra-wide' : ''}`} onClick={handleClick}>
      <img src={image} alt={category} className="category-image" />
      <div className="category-content">
        <div className="category-text">{category}</div>
        <button className="view-catalog-button" onClick={handleClick}>
          ABRIR
        </button>
      </div>
    </div>
  );
};

const Catalogo = () => {
  return (
    <div className="catalogo">
      <label className="catalogo-title">Nuestro amplio catálogo</label>
      <div className="catalogo-grid">
        {categories.map((category) => (
          <CategoryButton
            key={category.name}
            category={category.name}
            image={category.image}
            extraWide={category.extraWide}
          />
        ))}
      </div>
    </div>
  );
};

export default Catalogo;
