import React, { useEffect, useState } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import "./ProductCatalog.css";
import Preloader from "../Preloader/Preloader";

const ProductCatalog = ({ title, products }) => {
  const [searchVisible, setSearchVisible] = useState(window.innerWidth >= 768);
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [filteredProducts, setFilteredProducts] = useState(products);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
      setSearchVisible(window.innerWidth >= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, []);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchQuery = searchParams.get("search");

    if (searchQuery) {
      const lowerCaseQuery = searchQuery.toLowerCase();
      const newFilteredProducts = products.filter((product) =>
        product.name.toLowerCase().includes(lowerCaseQuery)
      );
      setFilteredProducts(newFilteredProducts);
    } else {
      setFilteredProducts(products);
    }
  }, [location.search, products]);

  const toggleSearchBar = () => {
    setSearchVisible(!searchVisible);
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleInfoButtonClick = (product) => {
    setSelectedProduct(product);
  };

  const handleCloseModal = () => {
    setSelectedProduct(null);
  };

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        setShowScrollTopButton(true);
      } else {
        setShowScrollTopButton(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (isLoading) {
    return <Preloader />;
  }

  return (
    <div className="catalog-container">
      <header className="header-catalog">
        <button className="back-button" onClick={() => navigate("/")}>
          {isMobile ? (
            <img
              src="/assets/fotos/backbutton.png"
              alt="Volver"
              className="back-button-image"
            />
          ) : (
            "Volver"
          )}
        </button>

        <h2>{title}</h2>
        <div className="search-title-container">
          <img
            src="/assets/fotos/lupa.png"
            alt="Buscar"
            className="search-button"
            onClick={toggleSearchBar}
          />
          <input
            type="text"
            placeholder="Buscar..."
            className={`search-bar ${searchVisible ? "visible" : ""}`}
            onChange={(e) => {
              const searchQuery = e.target.value.toLowerCase();
              const newFilteredProducts = products.filter((product) =>
                product.name.toLowerCase().includes(searchQuery)
              );
              setFilteredProducts(newFilteredProducts);
            }}
          />
        </div>
        <button
          className={`menu-button ${menuOpen ? "menu-open" : ""}`}
          onClick={toggleMenu}
        >
          <div className="menu-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
        <nav className={`nav ${menuOpen ? "open" : ""}`}>
          <NavLink
            to="/category/mariscos"
            className="nav-link"
            activeClassName="active"
          >
            Mariscos
          </NavLink>
          <NavLink
            to="/category/pescados"
            className="nav-link"
            activeClassName="active"
          >
            Pescados
          </NavLink>
          <NavLink
            to="/category/precocinados"
            className="nav-link"
            activeClassName="active"
          >
            Precocinados
          </NavLink>
          <NavLink
            to="/category/patatas"
            className="nav-link"
            activeClassName="active"
          >
            Patatas
          </NavLink>
          <NavLink
            to="/category/verduras"
            className="nav-link"
            activeClassName="active"
          >
            Verduras
          </NavLink>
          <NavLink
            to="/category/carnes"
            className="nav-link"
            activeClassName="active"
          >
            Carnes
          </NavLink>
          <NavLink
            to="/category/hielo y tartas"
            className="nav-link"
            activeClassName="active"
          >
            Tartas
          </NavLink>
          <NavLink
            to="/category/helados"
            className="nav-link"
            activeClassName="active"
          >
            Helados
          </NavLink>

          <NavLink
            to="/category/mariscos recien cocidos"
            className="nav-link"
            activeClassName="active"
          >
            M.Cocidos
          </NavLink>
        </nav>
      </header>
      <div className="protruding-section">
        <button className="info-button-section">i</button>
        para mostrar alérgenos
      </div>

      <div className="product-grid">
        {filteredProducts.map((product, index) => (
          <div className="product-card" key={index}>
            <div className="image-container">
              <img
                src={product.imgSrc}
                alt={product.name}
                className="product-image"
              />
            </div>
            <div className="product-info">
              <h3>{product.name}</h3>
              <button
                className="info-button"
                onClick={() => handleInfoButtonClick(product)}
              >
                i
              </button>
            </div>
          </div>
        ))}
      </div>
      {showScrollTopButton && (
        <button className="scroll-top-button" onClick={scrollToTop}>
          𖤂
        </button>
      )}
      {selectedProduct && (
        <div className="product-modal-overlay">
          <div className="product-modal-content">
            <h2>Alérgenos de {selectedProduct.name}</h2>
            <div className="product-allergens-container">
              {selectedProduct.allergens.map((allergen, index) => (
                <img
                  key={index}
                  src={`/assets/fotos/alergenos/${allergen}.png`}
                  alt={allergen}
                  className="allergen-image"
                />
              ))}
            </div>
            <button className="product-close-button" onClick={handleCloseModal}>
              Cerrar
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductCatalog;
