import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import('../ProductCatalog/ProductCatalog'));

const products = [
  {
    "name": "Plancha Caramelo Galleta",
    "imgSrc": "/assets/fotos/hielo/planchacaramelogalleta.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Chocolate Blanco",
    "imgSrc": "/assets/fotos/hielo/planchachocolateblanco.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha de Profiteroles",
    "imgSrc": "/assets/fotos/hielo/planchadeprofiteroles.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha de Queso y Arándanos",
    "imgSrc": "/assets/fotos/hielo/planchadequesoyarandanos.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Dulce de Leche",
    "imgSrc": "/assets/fotos/hielo/planchadulcedeleche.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Licor Café",
    "imgSrc": "/assets/fotos/hielo/planchalicorcafe.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Selva Negra",
    "imgSrc": "/assets/fotos/hielo/planchaselvanegra.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Tiramisú",
    "imgSrc": "/assets/fotos/hielo/planchatiramisu.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Plancha Tres Chocolates",
    "imgSrc": "/assets/fotos/hielo/planchatreschocolates.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Tarta de la Abuela",
    "imgSrc": "/assets/fotos/hielo/tartadelaabuela.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Tarta Queso",
    "imgSrc": "/assets/fotos/hielo/tartaqueso.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  },
  {
    "name": "Tarta Red Velvet",
    "imgSrc": "/assets/fotos/hielo/tartaredvelvet.png",
    "allergens": ["gluten", "huevos", "lacteos"]
  }
];

const Hielo = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="HIELO Y TARTAS" products={products} />
    </Suspense>
  );
};

export default Hielo;
