import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import "./CookieBanner.css";

const CookieBanner = () => {
  const [showModal, setShowModal] = useState(false);
  const [showBanner, setShowBanner] = useState(false);
  const [showAcceptButton, setShowAcceptButton] = useState(false);

  useEffect(() => {
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setShowBanner(true);
    } else if (cookieConsent === "rejected") {
      setShowAcceptButton(true);
    }
  }, []);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const openCookiePolicy = () => {
    openModal();
  };

  const acceptCookies = () => {
    const cookieOptions = { expires: 365 };
    Cookies.set("cookieConsent", "accepted", cookieOptions);
    Cookies.set("technicalCookies", true, cookieOptions);
    Cookies.set("personalizationCookies", true, cookieOptions);
    Cookies.set("statisticsCookies", true, cookieOptions);
    Cookies.set("marketingCookies", true, cookieOptions);
    Cookies.set("functionalCookies", true, cookieOptions);
    Cookies.set("socialCookies", true, cookieOptions);
    setShowBanner(false);
    setShowAcceptButton(false);
  };

  const rejectCookies = () => {
    Cookies.set("cookieConsent", "rejected", { expires: 365 });
    setShowBanner(false);
    setShowAcceptButton(true);
  };

  const reopenBanner = () => {
    setShowBanner(true);
    setShowAcceptButton(false);
  };

  return (
    <>
      {showBanner && (
        <div className={`cookie-banner ${showBanner ? "show" : ""}`}>
          <label>
            <h3>Esta página web usa cookies</h3>
            <p>
              Te informamos que el presente sitio web utiliza cookies propias y
              de terceros con las siguientes finalidades: Técnicas que permiten
              el buen funcionamiento de la web y ofrecerte una experiencia
              personalizada. De personalización, que si lo autorizas, recordarán
              tus preferencias en el sitio web. Estadísticas, que si lo
              autorizas, analizarán tu visita al sitio web con fines
              estadísticos. De marketing o publicidad comportamental las cuales
              analizarán tu visita al sitio web con la finalidad de analizar tu
              perfil. Cookies funcionales que facilitan funcionalidades adicionales. Y cookies de redes sociales para compartir contenido.
              Puedes aceptar el uso de todas las cookies pulsando el
              botón <strong>ACEPTAR</strong> y para rechazar todas las cookies
              opcionales puede pulsar el botón <strong>RECHAZAR</strong>.
            </p>
          </label>
          <a onClick={openCookiePolicy} style={{ cursor: "pointer" }}>
            Leer nuestra Política de Cookies
          </a>
          <button onClick={rejectCookies} className="reject-button">
            Rechazar Todas
          </button>
          <button onClick={acceptCookies}>Aceptar Todas</button>
        </div>
      )}
      {showModal && (
        <div className="banner-modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <h2>POLÍTICA DE COOKIES</h2>
            <div className="modal-body">
              <p>
                El objetivo de la presente Política es trasladar al usuario de
                manera transparente la existencia y el uso de cookies que
                realiza FRIPISA, S.L. (en adelante, “FRIPISA”) en su página web,
                informando sobre su definición, la tipología de cookies
                utilizadas, identificando quién las utiliza, así como las
                opciones para gestionar su consentimiento o su rechazo al empleo
                de las mismas, el periodo de conservación de los datos y aportar
                información relevante en materia de protección de datos.
              </p>
              <h3>Información general</h3>
              <h4>1. ¿Qué son las cookies?</h4>
              <p>
                Las cookies son ficheros que se descargan en el dispositivo del
                usuario (como un teléfono móvil u ordenador) a través de los
                sitios web que un usuario visita. Las cookies se descargan
                mientras se navega por Internet y su finalidad principal es
                recabar datos de navegación del usuario para mejorar los
                servicios y el funcionamiento de la página web, elaborar
                información estadística y mostrarle contenido personalizado.
              </p>
              <p>
                Le informamos que en el transcurso de su navegación por este
                dominio de FRIPISA se utilizan cookies propias técnicas, que
                permiten el acceso a la navegación y a los servicios que se
                ofrecen en el sitio web; cookies propias de personalización que,
                si lo autoriza, recordarán sus preferencias mientras navega por
                el sitio web; y cookies propias gestionadas por terceros que, si
                lo autoriza, analizarán su navegación con fines estadísticos.
                Los usos concretos que hacemos de estas tecnologías se
                describen a continuación.
              </p>
              <h4>2. ¿Para qué utilizamos las cookies en este sitio web?</h4>
              <p>
                En general, utilizamos las cookies para conocer la actividad de
                los visitantes a nuestra página web, en concreto para obtener
                información relacionada con la fecha y hora de visita, así como
                preferencias en sus hábitos de navegación, analizar sus hábitos
                de navegación para introducir cambios en el sitio web que
                mejoren su rendimiento o el acceso a productos en los que se
                haya mostrado mayor interés. También se utilizan para
                proporcionar información sobre la navegación de los usuarios a
                los propietarios de otros sitios web y sus colaboradores, con el
                fin de analizar el uso que realizan nuestra página web.
              </p>
              <p>
                Al navegar por nuestra página web se utilizan cookies que pueden
                clasificarse de la siguiente manera:
              </p>
              <h4>
                Según el plazo de conservación en el dispositivo del usuario:
              </h4>
              <ul>
                <li>
                  <strong>Las cookies de sesión</strong> son aquellas cuya
                  persistencia en el dispositivo empleado por el usuario se
                  limita al tiempo que el mismo esté navegando por el presente
                  sitio web. Están diseñadas para recabar y almacenar datos
                  mientras el usuario accede a una página web. Se suelen emplear
                  para almacenar información que solo interesa conservar para la
                  prestación del servicio solicitado por el usuario en una sola
                  ocasión.
                </li>
                <li>
                  <strong>Las cookies persistentes</strong> son aquellas que se
                  mantienen en el dispositivo del usuario más allá de la sesión
                  de navegación. En particular, permiten que los datos sigan
                  almacenados en el terminal y a los que se puede acceder y
                  tratar durante un periodo definido por el responsable de la
                  cookie, y que puede ir de unos minutos a varios años.
                </li>
              </ul>
              <h4>Según la Entidad que las gestione:</h4>
              <ul>
                <li>
                  <strong>Las cookies propias</strong> son aquellas creadas o
                  gestionadas por el responsable de la página web, en este caso,
                  FRIPISA, y que se envían al equipo terminal del usuario desde
                  un equipo o dominio gestionado por el propio editor y desde el
                  que se presta el servicio solicitado por los usuarios.
                  Utilizamos cookies propias persistentes con fines técnicos
                  para controlar la visualización del banner informativo sobre
                  cookies.
                </li>
                <li>
                  <strong>Las cookies de terceros</strong> son aquéllas que se
                  envían al equipo terminal del usuario desde un equipo o
                  dominio que no es gestionado por el editor, sino por otra
                  entidad que trata los datos obtenidos través de las cookies.
                </li>
              </ul>
              <h4>
                Según la finalidad para la que se traten los datos obtenidos a
                través de las cookies:
              </h4>
              <ul>
                <li>
                  <strong>Cookies técnicas (necesarias):</strong> Son aquellas
                  que permiten al usuario la navegación a través de una página
                  web, plataforma o aplicación y la utilización de las
                  diferentes opciones o servicios que en ella existan. Por
                  ejemplo, las utilizamos, principalmente, para el mantenimiento
                  de la sesión, el cálculo de tiempo de respuesta, realizar la
                  solicitud de inscripción o participación en un evento, usar
                  elementos de seguridad durante tu navegación y habilitar
                  contenidos dinámicos.
                </li>
                <li>
                  <strong>Cookies de personalización (preferencias):</strong> Son
                  aquéllas que permiten al usuario acceder al servicio con
                  algunas características de carácter general predefinidas, en
                  función de una serie de criterios en el terminal del usuario
                  (por ejemplo, el idioma, el tipo de navegador a través del
                  cual accede al servicio, la configuración regional desde donde
                  accede al servicio, etc.).
                </li>
                <li>
                  <strong>Cookies de análisis o de medición (estadísticas):</strong>
                  Son aquellas que permiten el seguimiento y análisis del
                  comportamiento de los usuarios de los sitios web a los que
                  están vinculadas. La información recogida mediante este tipo
                  de cookies se utiliza en la medición de la actividad de los
                  sitios web, aplicación o plataforma y para la elaboración de
                  perfiles de navegación de los usuarios de dichos sitios, con
                  el fin de introducir mejoras en función del análisis de los
                  datos de uso que hacen los usuarios del servicio.
                </li>
                <li>
                  <strong>Cookies publicitarias (marketing):</strong> Son aquéllas
                  que permiten la gestión, de la forma más eficaz posible, de
                  los espacios publicitarios que, en su caso, se hayan incluido
                  en una página web, aplicación o plataforma desde la que se
                  presta el servicio solicitado, en base a criterios como el
                  contenido editado o la frecuencia en la que se muestran los
                  anuncios. Incluye las Cookies de publicidad comportamental que
                  almacenan información del comportamiento de los usuarios
                  obtenida a través de la observación continuada de sus hábitos
                  de navegación, lo que permite desarrollar un perfil específico
                  para mostrar publicidad en función del mismo.
                </li>
              </ul>
              <h4>3. ¿Cómo puedo otorgar, denegar o revocar mi consentimiento?</h4>
              <p>
                El empleo de cookies imprescindibles y estrictamente necesarias
                para el correcto funcionamiento del sitio web no se encuentra
                sujeto a aceptación por parte del usuario. Por este motivo, no
                podrá modificar su valor en el Panel de Configuración de Cookies.
              </p>
              <p>
                La instalación de cualquier otra tipología de cookies empleadas
                en este sitio web se encuentra sujeto a aceptación expresa y
                previa por parte del usuario mediante la aceptación o denegación
                de las mismas a través del panel de configuración mostrado al
                abrir la página.
              </p>
              <p>
                Le informamos que, en caso de no seleccionar ninguna opción del
                panel de configuración, equivaldría a un rechazo, de modo que no
                serán instaladas ni empleadas en su dispositivo cookies que no
                sean indispensables.
              </p>
              <p>
                Finalmente, le informamos que nosotros no controlamos la
                persistencia de las cookies de terceros, por lo que, si acepta su
                uso, deberá eliminarlas desde las opciones del navegador o desde
                el sistema ofrecido por el propio tercero.
              </p>
              <h3>Información sobre Protección de Datos relacionada con Cookies</h3>
              <p>
                El empleo de cookies puede implicar la recopilación de datos
                personales de los usuarios de este sitio web. Por este motivo, en
                cumplimiento de la legislación vigente, ponemos en su conocimiento
                la siguiente información:
              </p>
              <h4>1. Responsable de tratamiento</h4>
              <p>
                El Responsable del tratamiento de sus datos de navegación,
                obtenidos a través de las cookies en nuestra página web es
                FRIPISA, S.L. (en adelante, “FRIPISA”)
              </p>
              <p>
                Datos de contacto del responsable: Calle Asdrubal, 59, 13.500 –
                Puertollano (Ciudad Real)
              </p>
              <p>
                Datos de contacto del Delegado de Protección de Datos:
                fripisasl@telefonica.net
              </p>
              <h4>2. Datos tratados</h4>
              <p>
                La tipología de datos que se tratan a través de las cookies son
                datos identificativos, en concreto, los datos obtenidos de sus
                navegaciones por el sitio web y la navegación que realice en la
                misma: historial de navegación (páginas visitadas y clics en
                contenidos).
              </p>
              <h4>3. Finalidades del tratamiento y Legitimación</h4>
              <p>
                Las finalidades del tratamiento están indicadas en el apartado 2
                "¿Para qué utilizamos las cookies en este sitio web?". Aquellas
                cookies que sean necesarias para el funcionamiento de nuestra
                página web se instalarán en su dispositivo. Para todos los demás
                tipos de cookies necesitamos su consentimiento.
              </p>
              <p>
                En particular, si consiente las cookies de publicidad y de
                publicidad comportamental (marketing), podremos utilizar los datos
                que obtengamos de su navegación para almacenarlos y elaborar un
                perfil sobre usted, de manera que podamos ofrecerle publicidad que
                pueda ser de su interés.
              </p>
              <h4>4. Destinatarios de los datos</h4>
              <p>
                Serán destinatarios de sus datos los terceros proveedores de
                Cookies que se encuentren instaladas en el presente sitio web.
              </p>
              <h4>5. Transferencias a terceros países</h4>
              <p>
                El tratamiento de los datos se realiza, con carácter general, en
                el Espacio Económico Europeo o en países que han sido declarados
                con un nivel adecuado de protección.
              </p>
              <h4>6. Periodo de conservación de los datos</h4>
              <p>
                Las Cookies se encontrarán instaladas por el tiempo necesario para
                cumplir con las finalidades que persiguen.
              </p>
              <h4>7. Derechos de los usuarios</h4>
              <p>
                Como titular de los datos usted tiene derecho a acceder,
                actualizar, rectificar y suprimir los datos, así como otros
                derechos, o solicitar más información sobre el tratamiento que
                realizamos de las cookies dirigiéndose al correo electrónico
                fripisasl@telefonica.net
              </p>
              <p>
                Tiene derecho a presentar cualquier reclamación ante la autoridad
                de control competente, considerando que la misma es, en España, la
                Agencia Española de Protección de Datos. Para poder ejercitar sus
                derechos en materia de protección de datos puede contactarnos a
                través de correo electrónico fripisasl@telefonica.net.
              </p>
              <h4>Actualizaciones y cambios en la Política de Cookies</h4>
              <p>
                FRIPISA realizará una revisión de la Política de cookies
                anualmente, salvo que existan exigencias normativas o de otra
                índole que hagan necesario adaptar la Política con una
                periodicidad inferior. Por tanto, se aconseja a los usuarios que
                consulten periódicamente su contenido, con el objetivo de estar
                informado adecuadamente sobre cómo y para qué utilizamos las
                cookies.
              </p>
              <p>Fecha de la última actualización: Agosto de 2024.</p>
            </div>
          </div>
        </div>
      )}
      {showAcceptButton && (
        <div className="accept-cookies-container" onClick={reopenBanner}>
          <img
            src="/assets/fotos/cookie.png"
            alt="Aceptar Cookies"
            className="accept-cookies-image"
          />
        </div>
      )}
    </>
  );
};

export default CookieBanner;
