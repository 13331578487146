import React, { forwardRef } from "react";
import emailjs from "emailjs-com";
import styles from "./Contact.module.css";

/*
login con noossolutions.ionos@gmail.com
pwd la misma que gmail
*/

class ContactInner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
      termsAccepted: false,
      formFeedback: "",
      isModalOpen: false,
    };
  }

  handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    this.setState({
      [name]: type === "checkbox" ? checked : value,
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (!this.state.termsAccepted) {
      this.setState({
        formFeedback:
          "Debes aceptar los términos y condiciones para enviar el formulario.",
      });
      return;
    }

    emailjs
      .sendForm(
        "service_kni2hdw",
        "template_zmsemu8",
        event.target,
        "1xCKOiiHSsu95uy-r"
      )
      .then(
        (result) => {
          this.setState({
            name: "",
            email: "",
            message: "",
            termsAccepted: false,
            formFeedback: "Se ha enviado el correo correctamente ✓",
          });
        },
        (error) => {
          this.setState({
            formFeedback:
              "Error al enviar el correo. Por favor, inténtalo de nuevo.",
          });
        }
      );
  };

  openModal = () => {
    this.setState({ isModalOpen: true });
  };

  closeModal = () => {
    this.setState({ isModalOpen: false });
  };

  render() {
    return (
      <div ref={this.props.forwardedRef} className={styles.contactPage}>
        <div className={styles.mapSection}>
          <h2 className={styles.mapSectionHeading}>Encuéntranos</h2>
          <p className={styles.mapSectionText}>
            Visita nuestras oficinas en una de las siguientes direcciones o
            ponte en contacto con nosotros de la forma que te resulte más
            cómoda.
          </p>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1747.3085180947392!2d-4.101489262711333!3d38.70555855812326!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0xd6b85cd02aacaf5%3A0x3c6a4aec38f20f42!2sFripisa!5e1!3m2!1ses!2ses!4v1719561387439!5m2!1ses!2ses"
            width="600"
            height="450"
            style={{ border: 0 }}
            allowFullScreen=""
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
            className={styles.mapSectionIframe}
          ></iframe>
        </div>

        <div className={styles.contactFormSection}>
          <h2 className={styles.contactFormHeading}>Escríbenos</h2>
          <p className={styles.contactFormText}>
            Rellena el siguiente formulario con tus datos de contacto para que
            podamos contactar contigo.
          </p>
          <form onSubmit={this.handleSubmit} className={styles.contactForm}>
            <div className={styles.formGroup}>
              <input
                type="text"
                name="name"
                value={this.state.name}
                onChange={this.handleChange}
                required
                placeholder="Nombre"
                className={styles.inputField}
              />
            </div>

            <div className={styles.formGroup}>
              <input
                type="email"
                name="email"
                value={this.state.email}
                onChange={this.handleChange}
                required
                placeholder="Correo electrónico"
                className={styles.inputField}
              />
            </div>

            <div className={styles.formGroup}>
              <textarea
                name="message"
                value={this.state.message}
                onChange={this.handleChange}
                placeholder="Escribe tu mensaje aquí (Opcional)"
                className={styles.inputField}
                rows="4"
                maxLength="500"
              ></textarea>
            </div>

            <p className={styles.dataProtectionText}>
              <strong>Información sobre Protección de Datos</strong>
              <br />
              Responsable: FRIPISA S.L. (“Fripisa”) Finalidad: Contactarle y
              atender las consultas y solicitudes de información remitidas a
              través del presente formulario. Asimismo, remitirte comunicaciones
              comerciales de productos comercializados por Fripisa.
              Legitimación: Consentimiento del interesado. Destinatarios: No se
              cederán sus datos a terceros, salvo obligación legal. Derechos:
              como titular de los datos usted tiene derecho a acceder,
              rectificar, suprimir y oponerse al tratamiento de sus datos, así
              como otros derechos, dirigiéndose a la dirección de correo
              electrónico fripisasl@telefonica.net. Información Adicional: Puede
              consultar la información adicional y detallada sobre Protección de
              datos en nuestra página web, apartado de “Protección de datos”.
            </p>

            <div className={styles.formGroup}>
              <label className={styles.checkboxLabel}>
                <input
                  type="checkbox"
                  name="termsAccepted"
                  checked={this.state.termsAccepted}
                  onChange={this.handleChange}
                  required
                  className={styles.checkbox}
                />
                <span onClick={this.openModal}>
                  He leído y acepto la política de protección de datos
                </span>
              </label>
            </div>

            <button type="submit" className={styles.submitButton}>
              Enviar
            </button>
            {this.state.formFeedback && (
              <div className={styles.formFeedback}>
                {this.state.formFeedback}
              </div>
            )}
          </form>
        </div>

        {this.state.isModalOpen && (
          <div className={styles.modalOverlay}>
            <div className={styles.modalContainer}>
              <div className={styles.modalContent}>
              <h2>Política de Protección de Datos</h2>
            <div className="modal-body">
              <h3>
                1. Responsable del Tratamiento ¿Quién es el responsable del
                tratamiento de tus datos?
              </h3>
              <p>
                1.1. FRIPISA, S.L. (en adelante, “FRIPISA”), con CIF B13035449 y
                domicilio en Calle Asdrubal, 59, 13.500 – Puertollano (Ciudad
                Real). Contáctanos por el correo electrónico de atención al
                cliente fripisasl@telefonica.net.
              </p>
              <p>
                1.2. El Delegado de Protección de Datos (en adelante DPD) es la
                persona encargada de salvaguardar su privacidad en nuestra
                Entidad. Si precisa contactar con él, puede hacerlo en
                fripisasl@telefonica.net o mediante carta remitida a la dirección
                de la Entidad, incluyendo la referencia “Delegado de Protección de
                Datos”.
              </p>
              <h3>2. Alcance ¿A quién aplica esta Información?</h3>
              <p>
                2.1. La presente información en materia de Protección de Datos
                aplica exclusivamente a los tratamientos que se realizan de los
                datos recabados a través de los formularios habilitados en las
                páginas web del responsable del tratamiento.
              </p>
              <h3>
                3. Procedencia y categoría de datos ¿Cuál es el origen y que
                categorías de datos tratamos?
              </h3>
              <p>
                3.1. Los datos personales que se gestionan a través de la presente
                página web serán exclusivamente los que nos facilite a través de
                los formularios habilitados. No se obtienen datos personales
                procedentes de otras fuentes.
              </p>
              <p>
                3.2. Las categorías de datos tratados, en los tratamientos de
                datos que se exponen en el apartado 4 de la presente Información,
                son datos identificativos y de contacto: nombre, apellidos y
                email, así como cualquier otro dato de carácter personal que usted
                incluya en el cuerpo del mensaje.
              </p>
              <h3>
                4. Finalidad ¿Con qué finalidad tratamos sus datos personales?
              </h3>
              <p>
                4.1. El Responsable tratará los datos identificativos y de
                contacto para ponernos en contacto usted y atender las consultas y
                solicitudes de información que nos remita a través de formularios
                habilitados en nuestra página web.
              </p>
              <p>
                4.2. En el caso de que lo autorice expresamente, y siempre que no
                sea cliente de FRIPISA, emplearemos tus datos de contacto para
                remitirle comunicaciones comerciales de productos comercializados
                por FRIPISA. Adicionalmente, en el caso de que nos facilite su
                número de teléfono en el formulario web, podremos tratar su dato
                de teléfono para contactarle y proporcionarle información
                comercial de nuestros productos. Asimismo, si nos facilita datos
                identificativos y de contacto durante la llamada serán utilizados
                con la finalidad de llevar un seguimiento del producto ofrecido
                durante la misma.
              </p>
              <p>
                4.3. No se prevé la realización de transferencias internacionales
                de datos, ni la elaboración de perfiles o decisiones automatizadas
                para los tratamientos identificados.
              </p>
              <h3>
                5. Legitimación ¿Cuál es la legitimación para el tratamiento de
                tus datos?
              </h3>
              <p>
                5.1. La legitimación para el tratamiento de tus datos personales
                para la finalidad enumerada en el apartado 4.1 es el
                consentimiento expreso y libre prestado por usted al marcar la
                casilla “He leído y acepto la Política de Protección de Datos”,
                así como la ejecución de medidas precontractuales a solicitud del
                interesado, en caso de que en su consulta se interese por los
                productos ofrecidos por FRIPISA.
              </p>
              <p>
                5.2. La finalidad para el tratamiento de tus datos personales para
                la finalidad enumerada en el apartado 4.2 es el consentimiento
                expreso y libre prestado por usted a través de la casilla “Deseo
                recibir comunicaciones comerciales de productos ofrecidos por
                FRIPISA”.
              </p>
              <h3>6. Conservación ¿Cuánto tiempo conservaremos tus datos?</h3>
              <p>
                6.1. Los datos recabados a través de los formularios habilitados
                en nuestras páginas web se conservarán durante el plazo de un año.
                Trascurrido ese plazo los datos se eliminarán.
              </p>
              <h3>
                7. Destinatarios ¿A qué destinatarios se comunicarán tus datos?
              </h3>
              <p>
                7.1. Los datos se tratarán por el responsable del tratamiento, así
                como por los agentes y proveedores con los que FRIPISA contrate la
                prestación de algún servicio, en caso de que resulte necesario.
                Ello se hará siempre con contratos y garantías sujetos a los
                modelos aprobados por las autoridades en materia de protección de
                datos.
              </p>
              <h3>8. Derechos ¿Cuáles son tus derechos?</h3>
              <p>
                8.1. Derecho de acceso: Derecho a conocer qué tipo de datos
                estamos tratando y las características de los tratamientos que
                estamos llevando a cabo, de acuerdo con lo establecido en el
                artículo 15 del Reglamento General de Protección de Datos (RGPD,
                en adelante).
              </p>
              <p>
                8.2. Derecho de rectificación: Derecho a solicitar la modificación
                de tus datos personales por ser éstos inexactos, no veraces o
                desactualizados.
              </p>
              <p>
                8.3. Derecho de oposición: Derecho a solicitar que no se traten
                tus datos personales para determinadas finalidades. Así, cuando el
                tratamiento esté basado en tu consentimiento, tienes derecho a
                retirar tal consentimiento en cualquier momento. Igualmente podrás
                oponerse al tratamiento de tus datos. Todo ello de acuerdo con el
                artículo 21 del RGPD.
              </p>
              <p>
                8.4. Derecho de cancelación o supresión: Derecho a solicitar la
                supresión de tus datos personales cuando el tratamiento ya no
                resulte necesario.
              </p>
              <p>
                8.5. Derecho limitación al tratamiento: Derecho a solicitar la
                limitación del tratamiento de tus datos, en cuyo caso únicamente
                se conservarán para el ejercicio o la defensa de reclamaciones,
                atención a requerimientos judiciales o a exigencias legales.
              </p>
              <p>
                8.6. Portabilidad. Derecho a solicitar la portabilidad de los
                datos que nos hayas facilitado, para recibirlos en un formato
                estructurado, de uso común y lectura mecánica, y a que se
                transmitan a otro responsable, de acuerdo con lo establecido en el
                artículo 20 del RGPD.
              </p>
              <p>
                8.7. Revocación del consentimiento. En cualquier momento puedes
                revocar el consentimiento que hayas prestado, sin ningún tipo de
                detrimento o perjuicio.
              </p>
              <p>
                8.8. Presentación de una reclamación. En cualquier momento puedes
                presentar reclamación ante la autoridad de control competente,
                considerando que la misma es, en España, la Agencia Española de
                Protección de Datos, sin perjuicio de las competencias que en su
                caso ostenten otras entidades autonómicas o supranacionales, de
                acuerdo con el Reglamento de Protección de Datos y la normativa
                nacional.
              </p>
              <p>
                También puedes acudir a nuestro Delegado de Protección de Datos a
                través de correo electrónico fripisasl@telefonica.net.
              </p>
              <p>
                Para el ejercicio de cualquiera de los derechos enunciados, puedes
                dirigir tu solicitud mediante escrito dirigido a FRIPISA en la
                dirección de correo electrónico fripisasl@telefonica.net,
                adjuntando copia de su DNI u otro documento oficial
                identificativo.
              </p>
            </div>
                <button
                  onClick={this.closeModal}
                  className={styles.closeButton}
                >
                  Aceptar
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const Contact = forwardRef((props, ref) => (
  <ContactInner {...props} forwardedRef={ref} />
));

export default Contact;
