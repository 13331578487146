import React, { useEffect } from "react";
import Slider from "react-slick";
import "./Home.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ImageSlider = () => {
  const sliderSettings = {
    autoplay: true,
    autoplaySpeed: 5000,
    speed: 1000,
    arrows: true,
    dots: false,
    adaptiveHeight: true,
    pauseOnFocus: false,
    pauseOnHover: false,
    beforeChange: () => progressBar(),
    afterChange: (index) => titleAnim(index),
  };

  useEffect(() => {
    progressBar();
    titleAnim(0);
  }, []);

  const progressBar = () => {
    const progressSpan = document.querySelector(".slider-progress span");
    if (progressSpan) {
      progressSpan.removeAttribute("style");
      progressSpan.classList.remove("active");
      setTimeout(() => {
        progressSpan.style.transitionDuration = "5s";
        progressSpan.classList.add("active");
      }, 100);
    }
  };

  const titleAnim = (index) => {
    const currentSlide = document.querySelector(
      `.slick-slide[data-index="${index}"]`
    );
    if (currentSlide) {
      const title = currentSlide.querySelector("h1");
      if (title) {
        title.classList.add("show");
        setTimeout(() => {
          title.classList.remove("show");
        }, 6500);
      }
    }
  };
  
  return (
    <div className="slider-wrap">
      <Slider {...sliderSettings} className="image-slider">
        <div className="img-wrap">
          <h1>Los mejores congelados dentro de tu nevera</h1>
          <img src="/assets/fotos/home1.jpg" alt="Slide 1" />
        </div>
        <div className="img-wrap">
          <h1>Marisco recién cocido Viernes y Sábado</h1>
          <img src="/assets/fotos/home4.jpg" alt="Slide 2" />
        </div>
        <div className="img-wrap">
          <h1>La mayor variedad de congelados</h1>
          <img src="/assets/fotos/home3.jpg" alt="Slide 3" />
        </div>
        <div className="img-wrap">
          <h1>Servicio de distribución a hostelería</h1>
          <img src="/assets/fotos/home2.jpg" alt="Slide 3" />
        </div>
      </Slider>
      <div className="slider-progress">
        <span></span>
      </div>
    </div>
  );
};

export default ImageSlider;
