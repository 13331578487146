import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() =>
  import("../ProductCatalog/ProductCatalog")
);

const products = [
  {
    name: "Almeja blanca",
    imgSrc: "/assets/fotos/pescado/almejablanca.png",
    allergens: ["moluscos"],
  },
  {
    name: "Almeja marron",
    imgSrc: "/assets/fotos/pescado/almejamarron.png",
    allergens: ["moluscos"],
  },
  {
    name: "Anillas de calamar",
    imgSrc: "/assets/fotos/pescado/anillascalamar.png",
    allergens: ["moluscos"],
  },
  {
    name: "Atun steak",
    imgSrc: "/assets/fotos/pescado/atunsteak.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao filete",
    imgSrc: "/assets/fotos/pescado/bacalaofilete.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao lomo",
    imgSrc: "/assets/fotos/pescado/bacalaolomo.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao lomocorto",
    imgSrc: "/assets/fotos/pescado/bacalaolomocorto.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao migas",
    imgSrc: "/assets/fotos/pescado/bacalaomigas.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao trozos",
    imgSrc: "/assets/fotos/pescado/bacalaotrozos.png",
    allergens: ["pescado"],
  },
  {
    name: "Bacalao ventresca",
    imgSrc: "/assets/fotos/pescado/bacalaoventresca.png",
    allergens: ["pescado"],
  },
  {
    name: "Boquerones",
    imgSrc: "/assets/fotos/pescado/boquerones.png",
    allergens: ["pescado"],
  },
  {
    name: "Botetinta calamar",
    imgSrc: "/assets/fotos/pescado/botetintacalamar.png",
    allergens: ["moluscos"],
  },
  {
    name: "Calamares nacional",
    imgSrc: "/assets/fotos/pescado/calamarnacional.png",
    allergens: ["moluscos"],
  },
  {
    name: "Calamar troceado",
    imgSrc: "/assets/fotos/pescado/calamartroceado.png",
    allergens: ["moluscos"],
  },
  {
    name: "Chipiron limpio",
    imgSrc: "/assets/fotos/pescado/chipironlimpio.png",
    allergens: ["moluscos"],
  },
  {
    name: "Chipiron sucio",
    imgSrc: "/assets/fotos/pescado/chipironsucio.png",
    allergens: ["moluscos"],
  },
  {
    name: "Colas rape",
    imgSrc: "/assets/fotos/pescado/colasrape.png",
    allergens: ["pescado"],
  },
  {
    name: "Colitas merluza",
    imgSrc: "/assets/fotos/pescado/colitasmerluza.png",
    allergens: ["pescado"],
  },
  {
    name: "Coquinas",
    imgSrc: "/assets/fotos/pescado/coquinas.png",
    allergens: ["moluscos"],
  },
  {
    name: "Dorada",
    imgSrc: "/assets/fotos/pescado/dorada.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete abadejo",
    imgSrc: "/assets/fotos/pescado/fileteabadejo.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete bacaladilla",
    imgSrc: "/assets/fotos/pescado/filetebacaladilla.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete caella",
    imgSrc: "/assets/fotos/pescado/filetecaella.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete de marrajo",
    imgSrc: "/assets/fotos/pescado/filetedemarrajo.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete emperador",
    imgSrc: "/assets/fotos/pescado/fileteemperador.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete limanda",
    imgSrc: "/assets/fotos/pescado/filetelimanda.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete merluza c-pkg",
    imgSrc: "/assets/fotos/pescado/filetemerluzac-p.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete merluza p-pkg",
    imgSrc: "/assets/fotos/pescado/filetemerluzac-p.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete pangas",
    imgSrc: "/assets/fotos/pescado/filetepanga.png",
    allergens: ["pescado"],
  },
  {
    name: "Filete tilapia",
    imgSrc: "/assets/fotos/pescado/filetetilapia.png",
    allergens: ["pescado"],
  },
  {
    name: "Frutos del mar",
    imgSrc: "/assets/fotos/pescado/frutosdelmar.png",
    allergens: ["pescado", "crustaceos", "moluscos"],
  },
  {
    name: "Gallos",
    imgSrc: "/assets/fotos/pescado/gallos.png",
    allergens: ["pescado"],
  },
  {
    name: "Lenguado mediano",
    imgSrc: "/assets/fotos/pescado/lenguadomediano.png",
    allergens: ["pescado"],
  },
  {
    name: "Lubina",
    imgSrc: "/assets/fotos/pescado/lubina.png",
    allergens: ["pescado"],
  },
  {
    name: "Lomos merluza",
    imgSrc: "/assets/fotos/pescado/lomosmerluza.png",
    allergens: ["pescado"],
  },
  {
    name: "Lomos salmon",
    imgSrc: "/assets/fotos/pescado/lomossalmon.png",
    allergens: ["pescado"],
  },
  {
    name: "Medallon merluza",
    imgSrc: "/assets/fotos/pescado/medallonmerluza.png",
    allergens: ["pescado"],
  },
  {
    name: "Mejillon godo",
    imgSrc: "/assets/fotos/pescado/mejillongordo1-2kg.png",
    allergens: ["moluscos"],
  },
  {
    name: "Mejillon media concha",
    imgSrc: "/assets/fotos/pescado/mejillonmediaconcha1-2kg.png",
    allergens: ["moluscos"],
  },
  {
    name: "Pata de pulpo cocida",
    imgSrc: "/assets/fotos/pescado/patadepulpococida.png",
    allergens: ["moluscos"],
  },
  {
    name: "Preparado paella rabade",
    imgSrc: "/assets/fotos/pescado/preparadopaellarabade.png",
    allergens: ["pescado", "crustaceos", "moluscos"],
  },
  {
    name: "Preparado paella silomar",
    imgSrc: "/assets/fotos/pescado/preparadopaellasilomar.png",
    allergens: ["pescado", "crustaceos", "moluscos"],
  },
  {
    name: "Pulpo cocido",
    imgSrc: "/assets/fotos/pescado/pulpococido(s,m,g).png",
    allergens: ["moluscos"],
  },
  {
    name: "Puntilla",
    imgSrc: "/assets/fotos/pescado/puntillaiqf.png",
    allergens: ["moluscos"],
  },
  {
    name: "Rejos",
    imgSrc: "/assets/fotos/pescado/rejos.png",
    allergens: ["moluscos"],
  },
  {
    name: "Rejos calamar",
    imgSrc: "/assets/fotos/pescado/rejoscalamar.png",
    allergens: ["moluscos"],
  },
  {
    name: "Rodaja de salmon",
    imgSrc: "/assets/fotos/pescado/rodajadesalmon.png",
    allergens: ["pescado"],
  },
  {
    name: "Rodaja de pulpo",
    imgSrc: "/assets/fotos/pescado/rodajapulpo.png",
    allergens: ["moluscos"],
  },
  {
    name: "Sardinas",
    imgSrc: "/assets/fotos/pescado/sardinas.png",
    allergens: ["pescado"],
  },
  {
    name: "Sepia mediana",
    imgSrc: "/assets/fotos/pescado/sepiamediana.png",
    allergens: ["moluscos"],
  },
  {
    name: "Sepia pequeña",
    imgSrc: "/assets/fotos/pescado/sepiapequeña.png",
    allergens: ["moluscos"],
  },
  {
    name: "Tinta calamar",
    imgSrc: "/assets/fotos/pescado/tintacalamar.png",
    allergens: ["moluscos"],
  },
  {
    name: "Tiras pota",
    imgSrc: "/assets/fotos/pescado/tiraspota.png",
    allergens: ["moluscos"],
  },
  {
    name: "Tubo poton silomar",
    imgSrc: "/assets/fotos/pescado/tubopotonsilomar.png",
    allergens: ["moluscos"],
  },
];

const Pescado = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="PESCADOS" products={products} />
    </Suspense>
  );
};

export default Pescado;
