import React from "react";
import "./BeneficiosAlimentosCongelados.css";

const BeneficiosAlimentosCongelados = () => {
  return (
    <div className="beneficios-container">
      <h2 className="title">Beneficios de los Alimentos Congelados</h2>
      <div className="beneficios-grid">
        <div className="beneficio-card">
          <h3>Conservación de Nutrientes</h3>
          <p>
            Los alimentos congelados conservan la mayoría de sus nutrientes, lo
            que garantiza una dieta saludable y balanceada.
          </p>
        </div>
        <div className="beneficio-card">
          <h3>Frescura</h3>
          <p>
            La congelación mantiene la frescura de los alimentos desde el
            momento en que son cosechados o preparados.
          </p>
        </div>
        <div className="beneficio-card">
          <h3>Reducción del Desperdicio</h3>
          <p>
            Al tener una vida útil más larga, los alimentos congelados ayudan a
            reducir el desperdicio de alimentos en el hogar.
          </p>
        </div>
        <div className="beneficio-card">
          <h3>Conveniencia</h3>
          <p>
            Los alimentos congelados son fáciles de preparar y permiten tener
            ingredientes siempre disponibles para cualquier receta.
          </p>
        </div>
        <div className="beneficio-card">
          <h3>Calidad y Seguridad</h3>
          <p>
            El proceso de congelación mata bacterias y mantiene la calidad de
            los alimentos, asegurando su seguridad para el consumo.
          </p>
        </div>
        <div className="beneficio-card">
          <h3>Variedad</h3>
          <p>
            La congelación permite disfrutar de una amplia variedad de alimentos
            fuera de temporada, ofreciendo más opciones todo el año.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BeneficiosAlimentosCongelados;
