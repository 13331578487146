import React from "react";
import "./HistoriaEmpresa.css";

const HistoriaEmpresa = () => {
  const eventos = [
    {
      description: "Desde nuestros primeros días como pequeña empresa familiar, siempre hemos creído en la importancia de cuidar cada detalle para que nuestros clientes disfruten productos de calidad.",
      
    },
    {
      
      description: "A lo largo de casi 40 años, Fripisa ha crecido y evolucionado, y hoy somos parte de la vida de muchas familias que confían en nosotros. ",
     
    },
    {
     
      description: "Para nosotros, no es solo una empresa; es nuestra manera de llegar a nuestros clientes, de compartir aquello que amamos y de mantenernos fieles a nuestra esencia.",
     
    },
    {
      
      description: "En Fripisa, cada bocado cuenta una historia de esfuerzo y dedicación.",
      
    },
    {
     
      description: "Es un orgullo seguir llevando lo mejor del mar hasta tu mesa, manteniendo la misma cercanía y compromiso que nos inspiraron desde el principio.",
      
    },
  ];

  return (
    <div className="historia-container">
      <h6 className="historia-title">Nuestra Historia</h6>
      <p className="historia-subtitle">
      Fripisa Congelados nació en Puertollano en 1985 con una misión sencilla: acercar a cada hogar el sabor y la frescura del mar. 
      </p>

      <div className="timeline">
        {eventos.map((event, index) => (
          <div key={index} className="timeline-event">
            <div
              className="timeline-icon"
            >
            </div>
            <div className="timeline-details">
              <div className="timeline-year" style={{ color: event.color }}>
                
              </div>
              <div className="timeline-title">{event.title}</div>
              <div className="timeline-description">{event.description}</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default HistoriaEmpresa;
