import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import('../ProductCatalog/ProductCatalog'));

const products = [
    {
        "name": "Patata 3.8 Caterfritis",
        "imgSrc": "/assets/fotos/patatas/patata3.8caterfritis.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata 6.6",
        "imgSrc": "/assets/fotos/patatas/patata6.6mc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata 9.9",
        "imgSrc": "/assets/fotos/patatas/patata9.9mc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Brava Eurofritis",
        "imgSrc": "/assets/fotos/patatas/patatabravaeurofritis.PNG",
        "allergens": ["gluten"]
    },
    {
        "name": "Patata Brava",
        "imgSrc": "/assets/fotos/patatas/patatabravamc.PNG",
        "allergens": ["gluten"]
    },
    {
        "name": "Patata Casera",
        "imgSrc": "/assets/fotos/patatas/patatacaseramc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Crispers",
        "imgSrc": "/assets/fotos/patatas/patatacrispersmc.PNG",
        "allergens": ["gluten"]
    },
    {
        "name": "Patata Dolar",
        "imgSrc": "/assets/fotos/patatas/patatadolarmc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Eurofritis 1kg",
        "imgSrc": "/assets/fotos/patatas/patataeurofritis1kg.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Gajo",
        "imgSrc": "/assets/fotos/patatas/patatagajomc.PNG",
        "allergens": ["gluten"]
    },
    {
        "name": "Patata Paillason",
        "imgSrc": "/assets/fotos/patatas/patatapaillasonmc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Pochada",
        "imgSrc": "/assets/fotos/patatas/patatapochadafindus.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Risi",
        "imgSrc": "/assets/fotos/patatas/patatarisimc.PNG",
        "allergens": ["ninguno"]
    },
    {
        "name": "Patata Rizada",
        "imgSrc": "/assets/fotos/patatas/patatarizadamc.PNG",
        "allergens": ["ninguno"]
    }
];

const Patata = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ProductCatalog title="PATATAS" products={products} />
        </Suspense>
    );
};

export default Patata;
