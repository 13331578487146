import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import('../ProductCatalog/ProductCatalog'));

const products = [
    {
        "name": "Alcachofa Flor",
        "imgSrc": "/assets/fotos/verduras/alcachofaflor.png",
        "allergens": ["gluten", "huevos", "lacteos"]
    },
    {
        "name": "Arroz 5 Delicias",
        "imgSrc": "/assets/fotos/verduras/arroz5delicias.png",
        "allergens": ["gluten", "soja", "lacteos"]
    },
    {
        "name": "Boletus Trozos",
        "imgSrc": "/assets/fotos/verduras/boletustrozos.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Brocoli",
        "imgSrc": "/assets/fotos/verduras/brocoli.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Cebolla Dado",
        "imgSrc": "/assets/fotos/verduras/cebolladado.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Coliflor",
        "imgSrc": "/assets/fotos/verduras/coliflor.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Ensaladilla",
        "imgSrc": "/assets/fotos/verduras/ensaladilla.png",
        "allergens": ["gluten", "huevos", "lacteos"]
    },
    {
        "name": "Esparrago Triguero",
        "imgSrc": "/assets/fotos/verduras/esparragotriguero.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Espinacas Hojas",
        "imgSrc": "/assets/fotos/verduras/espinacashojas.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Guisantes",
        "imgSrc": "/assets/fotos/verduras/guisantes.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Judia Plana",
        "imgSrc": "/assets/fotos/verduras/judiaplana.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Judia Redonda",
        "imgSrc": "/assets/fotos/verduras/judiaredonda.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Mezcla Frutos Rojos",
        "imgSrc": "/assets/fotos/verduras/mezclafrutosrojos.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Mezcla Setas",
        "imgSrc": "/assets/fotos/verduras/mezclasetas.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Menestra Especial",
        "imgSrc": "/assets/fotos/verduras/menestraespecial.png",
        "allergens": ["gluten", "soja"]
    },
    {
        "name": "Revuelto Ajetes y Gambas",
        "imgSrc": "/assets/fotos/verduras/revueltoajetesygambas.png",
        "allergens": ["mariscos", "huevos"]
    },
    {
        "name": "Salteado Campestre",
        "imgSrc": "/assets/fotos/verduras/salteadocampestre.png",
        "allergens": ["gluten", "lacteos"]
    },
    {
        "name": "Sofrito Verduras",
        "imgSrc": "/assets/fotos/verduras/sofritoverduras.png",
        "allergens": ["ninguno"]
    },
    {
        "name": "Verdura Tempura Findus",
        "imgSrc": "/assets/fotos/verduras/verduratempurafindus.png",
        "allergens": ["gluten", "huevos"]
    },
    {
        "name": "Zanahoria Baby",
        "imgSrc": "/assets/fotos/verduras/zanahoriababy.png",
        "allergens": ["ninguno"]
    }
];

const Verdura = () => {
    return (
        <Suspense fallback={<div>Loading...</div>}>
            <ProductCatalog title="VERDURAS" products={products} />
        </Suspense>
    );
};

export default Verdura;
