import React, { Suspense } from "react";

// Usar React.lazy para carga diferida del componente ProductCatalog
const ProductCatalog = React.lazy(() => import("../ProductCatalog/ProductCatalog"));

const products = [
  {
    name: "Alas Adobadas",
    imgSrc: "/assets/fotos/carne/alasadobadas.png",
    allergens: ["gluten"],
  },
  {
    name: "Alas Barbacoa",
    imgSrc: "/assets/fotos/carne/alasbarbacoa.png",
    allergens: ["gluten"],
  },
  {
    name: "Alas Crujientes",
    imgSrc: "/assets/fotos/carne/alascrujientes.png",
    allergens: ["gluten"],
  },
  {
    name: "Alas Pollo",
    imgSrc: "/assets/fotos/carne/alaspollo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Callos Ternera",
    imgSrc: "/assets/fotos/carne/callosternera.png",
    allergens: ["gluten"],
  },
  {
    name: "Cinta Lomo",
    imgSrc: "/assets/fotos/carne/cintalomo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Cochifrito Ibérico",
    imgSrc: "/assets/fotos/carne/cochifritoiberico.png",
    allergens: ["ninguno"],
  },
  {
    name: "Codornices",
    imgSrc: "/assets/fotos/carne/codornices.png",
    allergens: ["ninguno"],
  },
  {
    name: "Jamoncitos Pollo",
    imgSrc: "/assets/fotos/carne/jamoncitospollo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Pluma Cerdo",
    imgSrc: "/assets/fotos/carne/plumacerdo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Presa Cerdo",
    imgSrc: "/assets/fotos/carne/presacerdo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Redondo Pavo",
    imgSrc: "/assets/fotos/carne/redondopavo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Secreto Cerdo",
    imgSrc: "/assets/fotos/carne/secretocerdo.png",
    allergens: ["ninguno"],
  },
  {
    name: "Solomillo Cerdo",
    imgSrc: "/assets/fotos/carne/solomillocerdo.png",
    allergens: ["ninguno"],
  },
];

const Carne = () => {
  return (
    <Suspense fallback={<div>Loading...</div>}>
      <ProductCatalog title="CARNES" products={products} />
    </Suspense>
  );
};

export default Carne;
